.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #282c34;
  color: white;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  padding: 10px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  background-color: #61dafb;
  color: black;
}

button:hover {
  background-color: #21a1f1;
}

.error {
  color: red;
}

span {
  font-size: 25px;
  color: rgba(84, 84, 84, 0.991);
}