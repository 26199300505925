body {
  background-color: #77777758;
}

.login-box {
  background-color: #77777758;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#logo {
  background: var(--custom_logo_login);
  background-size: var(--custom_logo_size);
  width: 100%;
  height: 110px;
}